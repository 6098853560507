/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from "react"
import {isEmptyString, isNullUndefined} from "../util/util"
import {MainContainer} from "../constants/component.constants"
import {useLocation} from "react-router"
import {rebuildLeaseHistory, rebuildOwnershipHistory, rebuildSubscriptionHistory} from "../services/repair.service";

export default function Repairs() {
    const [responseMessage, setResponseMessage] = useState("Processing!!! ... ")

    function useQuery() {
        const {search} = useLocation()
        return useMemo(() => new URLSearchParams(search), [search])
    }

    const routeParams = useQuery()
    let url = window.location.href
    let isRebuildLeaseHistory = url.indexOf("rebuildLeaseHistory") > 1
    let isRebuildOwnershipHistory = url.indexOf("rebuildOwnershipHistory") > 1
    let isRebuildSubscriptionHistory = url.indexOf("rebuildSubscriptionHistory") > 1
    let billingCode = routeParams.get("billingCode")

    useEffect(() => {
        if (!isNullUndefined(billingCode) && !isEmptyString(billingCode)) {
            if (isRebuildLeaseHistory) {
                rebuildLeaseHistory(billingCode).then((response) => {
                    setResponseMessage(response)
                })
            } else if (isRebuildOwnershipHistory) {
                rebuildOwnershipHistory(billingCode).then((response) => {
                    setResponseMessage(response)
                })
            }else if (isRebuildSubscriptionHistory) {
                rebuildSubscriptionHistory(billingCode).then((response) => {
                    setResponseMessage(response)
                })
            }
        } else {
            setResponseMessage("No billing code specified")
        }
    }, [])

    return (
        <MainContainer>
            {JSON.stringify(responseMessage)}
        </MainContainer>
    )
}
