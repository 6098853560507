/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect, useState } from "react";
import { formatDateTime, isNullUndefined } from "../../util/util";
import { getMeterBillingNotes } from "../../services/meterbilling.service";
import { getOwnershipNotes } from "../../services/ownership.service";
import { getLeaseNotes } from "../../services/lease.service";
import { ResponsiveRow } from "../../constants/layout.constants";
import { getListingApplicationNotes } from "../../services/listingsServices/listing.service";
import { getSubscriptionNotes } from "../../services/subscription.service";

/**
 Required props are:
 - contract: This is the data to be displayed
 - notesMode: This is actual page you are implementing this from.
 */

export default function NoteLogsCard(props) {
  const { width, height } = props;
  const [notes, setNotes] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [showLoadMore, setShowLoadMore] = useState(true);

  useEffect(() => {
    if (
      !isNullUndefined(props.contract) &&
      !isNullUndefined(props.contract.id)
    ) {
      if (props.notesMode === "Meter Billing") {
        getMeterBillingNotes(props.contract.id, pageNo)
          .then((items) => {
            if (items.length > 0) {
              let tmp = [];
              notes.forEach((note) => {
                tmp.push(note);
              });
              items.forEach((note) => {
                tmp.push(note);
              });
              setNotes(tmp);
            } else {
              setShowLoadMore(false);
            }
          })
          .catch((error) => {
            console.log("getNotes Error: ", error);
          });
      }
      if (props.notesMode === "Ownership") {
        getOwnershipNotes(props.contract.id, pageNo)
          .then((items) => {
            if (items.length > 0) {
              let tmp = [];
              notes.forEach((note) => {
                tmp.push(note);
              });
              items.forEach((note) => {
                tmp.push(note);
              });
              setNotes(tmp);
            } else {
              setShowLoadMore(false);
            }
          })
          .catch((error) => {
            console.log("getNotes Error", error);
          });
      }
      if (props.notesMode === "Lease") {
        getLeaseNotes(props.contract.id, pageNo)
          .then((items) => {
            if (items.length > 0) {
              let tmp = [];
              notes.forEach((note) => {
                tmp.push(note);
              });
              items.forEach((note) => {
                tmp.push(note);
              });
              setNotes(tmp);
            } else {
              setShowLoadMore(false);
            }
          })
          .catch((error) => {
            console.log("getNotes Error", error);
          });
      }
      if (props.notesMode === "ListingApplication") {
        getListingApplicationNotes(props.contract.id, pageNo)
          .then((items) => {
            if (items.length > 0) {
              let tmp = [];
              notes.forEach((note) => {
                tmp.push(note);
              });
              items.forEach((note) => {
                tmp.push(note);
              });
              setNotes(tmp);
            } else {
              setShowLoadMore(false);
            }
          })
          .catch((error) => {
            console.log("getNotes Error", error);
          });
      }
      if (props.notesMode === "Client") {
        getSubscriptionNotes(props.contract.id, pageNo)
          .then((items) => {
            if (!isNullUndefined(items) && items.length > 0) {
              setNotes(items);
            } else {
              setShowLoadMore(false);
            }
          })
          .catch((error) => {
            console.log("getNotes Error", error);
          });
      }
    }
  }, [props.contract, pageNo]);

  return (
    <ResponsiveRow
      sx={{
        flexDirection: "column",
        flexWrap: "nowrap",

        width: { xs: "100%", lg: width ? width : "100%" },
        minHeight: "400px",
        height: { xs: "100%", lg: height ? height : "100%" },

        boxShadow: "0 4px 12px 0 rgba(0,0,0,0.25)",
        backgroundColor: "#fff",
        borderRadius: "10px",

        margin: { xs: "10px 0", lg: "10px" },
        padding: 0,

        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <ResponsiveRow
        sx={{
          flexDirection: "row",
          flexWrap: "nowrap",

          justifyContent: "space-between",
          alignItems: "center",

          width: "100%",
          height: "70px",

          padding: "10px 20px",
        }}
      >
        <Typography variant="h5" color="primary.dark" fontWeight={700}>
          Note Logs
        </Typography>

        {showLoadMore && (
          <Button
            size="small"
            onClick={() => {
              setPageNo(pageNo + 1);
            }}
          >
            load more
            <ChevronRightIcon sx={{ margin: "auto" }} />
          </Button>
        )}
      </ResponsiveRow>

      <ResponsiveRow
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",

          backgroundColor: "#EFEFEF",
          borderRadius: "10px",

          height: "100%",
          width: "100%",

          padding: "10px 10px 0 10px",
          margin: "5px 0 0 0",

          justifyContent: "flex-start",
          alignItems: "center",

          overflowY: "auto",
        }}
      >
        <ResponsiveRow
          sx={{
            flexDirection: "column",
            alignItems: "center",
            flexWrap: "wrap",

            width: "100%",
            minHeight: "100%",

            maxHeight: { xs: "350px", lg: "100%" },

            padding: "10px 5px",

            backgroundColor: "#efefef",
            boxShadow: 0,
          }}
        >
          {notes && notes.length < 1 && (
            <Typography
              sx={{
                color: "#C6C5C5",
                fontSize: "11px",
                fontWeight: 700,
                width: "100%",
                minHeight: "100%",
                textAlign: "center",
              }}
            >
              There are no notes for this {props.notesMode}
            </Typography>
          )}
          {notes &&
            notes.length > 0 &&
            notes.map(function (note, i) {
              return (
                <Card
                  key={i}
                  sx={{ width: "100%", marginBottom: "5px", boxShadow: 0 }}
                >
                  <CardActionArea>
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "start",
                      }}
                    >
                      <Typography
                        gutterBottom
                        sx={{
                          fontWeight: 800,
                          // color: "#037171",
                          fontSize: ".9rem",
                        }}
                        component="div"
                      >
                        {formatDateTime(note.dateCreated)}
                      </Typography>
                      <Typography
                        gutterBottom
                        sx={{
                          fontWeight: 600,
                          // color: "#037171",
                          fontSize: ".8rem",
                        }}
                        component="div"
                      >
                        {note.createdBy}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        textAlign="start"
                      >
                        {note.note}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            }, this)}
        </ResponsiveRow>
      </ResponsiveRow>
    </ResponsiveRow>
  );
}
