import TransactionHistory from "../components/transactionHistory";
import { DocumentStore } from "../components/documentStore";
import { ResponsiveRow } from "../constants/layout.constants";
import Permit from "../util/permit";
import {formatCurrency, formatDate} from "../util/util";
import {
    DetailsCardContainer,
    DetailsColumn,
    DetailsContainer, DetailsLabel, DetailsRow, DetailsText,
    TopBarContainedBrandButton,
    TopBarContainedCancelButton,
    TopBarContainer
} from "../constants/component.constants";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";

export default function CustomerView(props) {
  return (
    <Permit
      roles="BLDIR,BLDMG,CS"
      services="MAINT,BLWTR"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      <ResponsiveRow
        container
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",
          width: "100%",
          height: "auto",
          justifyContent: { xs: "flex-start", lg: "flex-start" },
          alignItems: "flex-start",
          padding: 0,
        }}
      >
          <TopBarContainer
              container
              sx={{
                  width: {xs: "100%", lg: "80%"},
                  boxShadow: "4px 4px 12px #aaa",
                  padding: "0 20px",
              }}
          >
              <ResponsiveRow
                  sx={{
                      width: {xs: "100%", lg: "50%"},
                      justifyContent: {xs: "center", lg: "flex-start"},
                  }}
              >
                  <Permit services="BLWTR">
                      <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="en-gb"
                      >
                          <DatePicker
                              sx={{width: "90%"}}
                              id={"statementYear"}
                              value={dayjs(props.statementYear)}
                              disableFuture={true}
                              disablePast={false}
                              openTo="year"
                              views={["year"]}
                              isrequired={true}
                              onChange={(newDate) => props.handleStatementYearChange(newDate)}

                          />
                      </LocalizationProvider>
                  </Permit>
              </ResponsiveRow>

              <ResponsiveRow
                  sx={{
                      width: {xs: "100%", lg: "50%"},
                      justifyContent: {xs: "center", lg: "flex-end"},
                  }}
              >
                  <Permit services="BLWTR">
                      <TopBarContainedBrandButton
                          onClick={props.handlePrintCustomerStatement}
                          sx={{
                              width: {xs: "100%", lg: "300px"},
                              height: "50px",
                          }}
                      >
                          Print Customer Sales Statement
                      </TopBarContainedBrandButton>
                  </Permit>
                  <TopBarContainedCancelButton
                      type="submit"
                      sx={{width: {xs: "100%", lg: "200px"}}}
                      onClick={() => props.setViewDiv(false)}
                  >
                      Close
                  </TopBarContainedCancelButton>
              </ResponsiveRow>
          </TopBarContainer>

          <Permit services="MAINT">
              <ResponsiveRow
                  sx={{
                      width: "100%",
                      height: "auto",
                      flexDirection: { xs: "column", lg: "row" },
                      flexWrap: "nowrap",
                      justifyContent: { xs: "center", lg: "space-between" },
                      alignItems: "center",
                      padding: 0,
                      margin: "10px auto 5px auto",
                  }}
              >
                  <DetailsCardContainer container spacing={1}>
                      <DetailsContainer
                          sx={{height: "auto", width: "100%"}}
                          container
                      >
                          <DetailsRow>
                              <Typography
                                  fontWeight={800}
                                  variant="h2"
                                  color="primary.dark"
                                  sx={{
                                      fontSize: "18px",
                                  }}
                              >
                                  Customer Details for{" "}
                                  {props && props.selectedCustomer && props.selectedCustomer.name}
                              </Typography>
                          </DetailsRow>
                          <br/>
                          {
                              props && props.selectedCustomer && props.selectedCustomer.type &&
                              props.selectedCustomer.type === "Individual" && (
                                  <>
                                  <DetailsColumn
                                      item
                                      sx={{width: {xs: "100%", lg: "33.3%"}}}
                                  >
                                      <Typography
                                          sx={{marginBottom: "5px", fontSize: "20px"}}
                                          id="modal-modal-title"
                                          variant="h5"
                                          component="h4"
                                      >
                                          Customer Information
                                      </Typography>
                                      <DetailsRow>
                                          <DetailsLabel> First Name: </DetailsLabel>
                                          <DetailsText>
                                              {props && props.selectedCustomer && props.selectedCustomer.firstName}
                                          </DetailsText>
                                      </DetailsRow>
                                      <DetailsRow>
                                          <DetailsLabel> Last Name: </DetailsLabel>
                                          <DetailsText>
                                              {props && props.selectedCustomer && props.selectedCustomer.lastName}
                                          </DetailsText>
                                      </DetailsRow>
                                      <DetailsRow>
                                          <DetailsLabel> ID Number: </DetailsLabel>
                                          <DetailsText>
                                              {props && props.selectedCustomer && props.selectedCustomer.idNumber}
                                          </DetailsText>
                                      </DetailsRow>
                                  </DetailsColumn>
                                      <DetailsColumn
                                          item
                                          sx={{width: {xs: "100%", lg: "33.3%"}}}
                                      >
                                          <Typography
                                              sx={{marginBottom: "5px", fontSize: "20px"}}
                                              id="modal-modal-title"
                                              variant="h5"
                                              component="h4"
                                          >
                                              Status Information
                                          </Typography>
                                          <DetailsRow>
                                              <DetailsLabel> Status: </DetailsLabel>
                                              <DetailsText>
                                                  {" "}
                                                  {props && props.selectedCustomer && props.selectedCustomer.statusCd === "ACT" ? "Active" : "Domant"}
                                              </DetailsText>
                                          </DetailsRow>
                                      </DetailsColumn>
                                      <DetailsColumn
                                          item
                                          sx={{width: {xs: "100%", lg: "33.3%"}}}
                                      >
                                          <Typography
                                              sx={{marginBottom: "5px", fontSize: "20px"}}
                                              id="modal-modal-title"
                                              variant="h5"
                                              component="h4"
                                          >
                                              Contact Information
                                          </Typography>
                                          <DetailsRow>
                                              <DetailsLabel> Email: </DetailsLabel>
                                              <DetailsText>
                                                  {props && props.selectedCustomer &&
                                                      props.selectedCustomer.email
                                                  }
                                              </DetailsText>
                                          </DetailsRow>
                                          <DetailsRow>
                                              <DetailsLabel> Phone Number: </DetailsLabel>
                                              <DetailsText>
                                                  {
                                                      props && props.selectedCustomer &&
                                                      props.selectedCustomer.mobilePhone
                                                  }
                                              </DetailsText>
                                          </DetailsRow>
                                      </DetailsColumn>
                      </>
                              )
                          }

                          {
                              props && props.selectedCustomer && props.selectedCustomer.type &&
                              props.selectedCustomer.type === "Company" && (
                                  <>
                                  <DetailsColumn
                                      item
                                      sx={{width: {xs: "100%", lg: "33.3%"}}}
                                  >
                                      <Typography
                                          sx={{marginBottom: "5px", fontSize: "20px"}}
                                          id="modal-modal-title"
                                          variant="h5"
                                          component="h4"
                                      >
                                          Customer Information
                                      </Typography>
                                      <DetailsRow>
                                          <DetailsLabel> Customer Name: </DetailsLabel>
                                          <DetailsText>
                                              {props && props.selectedCustomer && props.selectedCustomer.name}
                                          </DetailsText>
                                      </DetailsRow>
                                      <DetailsRow>
                                          <DetailsLabel> Address: </DetailsLabel>
                                          <DetailsText>
                                              {props && props.selectedCustomer && props.selectedCustomer.address &&
                                                  props.selectedCustomer.address.fullAddress1}
                                          </DetailsText>
                                      </DetailsRow>
                                      <DetailsRow>
                                          <DetailsLabel> Description: </DetailsLabel>
                                          <DetailsText>
                                              {" "}
                                              {props && props.selectedCustomer && props.selectedCustomer.description}
                                          </DetailsText>
                                      </DetailsRow>
                                  </DetailsColumn>
                                      <DetailsColumn
                                          item
                                          sx={{width: {xs: "100%", lg: "33.3%"}}}
                                      >
                                          <Typography
                                              sx={{marginBottom: "5px", fontSize: "20px"}}
                                              id="modal-modal-title"
                                              variant="h5"
                                              component="h4"
                                          >
                                              Status Information
                                          </Typography>
                                          <DetailsRow>
                                              <DetailsLabel> Status: </DetailsLabel>
                                              <DetailsText>
                                                  {" "}
                                                  {props && props.selectedCustomer && props.selectedCustomer.statusCd === "ACT" ? "Active" : "Domant"}
                                              </DetailsText>
                                          </DetailsRow>
                                      </DetailsColumn>
                                      <DetailsColumn
                                          item
                                          sx={{width: {xs: "100%", lg: "33.3%"}}}
                                      >
                                          <Typography
                                              sx={{marginBottom: "5px", fontSize: "20px"}}
                                              id="modal-modal-title"
                                              variant="h5"
                                              component="h4"
                                          >
                                              Contact Information
                                          </Typography>
                                          <DetailsRow>
                                              <DetailsLabel> Contact Name: </DetailsLabel>
                                              <DetailsText>
                                                  {  props && props.selectedCustomer &&
                                                      props.selectedCustomer.contact && props.selectedCustomer.contact.name
                                                  }
                                              </DetailsText>
                                          </DetailsRow>
                                          <DetailsRow>
                                              <DetailsLabel> Email: </DetailsLabel>
                                              <DetailsText>
                                                  {  props && props.selectedCustomer &&
                                                      props.selectedCustomer.contact && props.selectedCustomer.contact.email
                                                  }
                                              </DetailsText>
                                          </DetailsRow>
                                          <DetailsRow>
                                              <DetailsLabel> Phone: </DetailsLabel>
                                              <DetailsText>
                                                  {  props && props.selectedCustomer &&
                                                      props.selectedCustomer.contact && props.selectedCustomer.contact.mobilePhone
                                                  }
                                              </DetailsText>
                                          </DetailsRow>
                                      </DetailsColumn>
                              </>
                              )
                          }
                      </DetailsContainer>
                  </DetailsCardContainer>
              </ResponsiveRow>
          </Permit>

          <Permit services="BLWTR">
              <ResponsiveRow
                  sx={{
                      width: "100%",
                      height: "auto",
                      flexDirection: { xs: "column", lg: "row" },
                      flexWrap: "nowrap",
                      justifyContent: { xs: "center", lg: "space-between" },
                      alignItems: "center",
                      padding: 0,
                      margin: "10px auto 5px auto",
                  }}
              >
                  <DetailsCardContainer container spacing={1}>
                      <DetailsContainer
                          sx={{height: "auto", width: "100%"}}
                          container
                      >
                          <DetailsRow>
                              <Typography
                                  fontWeight={800}
                                  variant="h2"
                                  color="primary.dark"
                                  sx={{
                                      fontSize: "18px",
                                  }}
                              >
                                  Customer Details for{" "}
                                  {props && props.selectedCustomer && props.selectedCustomer.name}
                              </Typography>
                          </DetailsRow>
                          <br/>
                          <DetailsColumn
                              item
                              sx={{width: {xs: "100%", lg: "33.3%"}}}
                          >
                              <Typography
                                  sx={{marginBottom: "5px", fontSize: "20px"}}
                                  id="modal-modal-title"
                                  variant="h5"
                                  component="h4"
                              >
                                  Customer Information
                              </Typography>
                              <DetailsRow>
                                  <DetailsLabel> Customer Name: </DetailsLabel>
                                  <DetailsText>
                                      {props && props.selectedCustomer && props.selectedCustomer.name}
                                  </DetailsText>
                              </DetailsRow>
                              <DetailsRow>
                                  <DetailsLabel> Effective Date: </DetailsLabel>
                                  <DetailsText>
                                      {" "}
                                      {props && props.selectedCustomer && props.selectedCustomer.effectiveDate}
                                  </DetailsText>
                              </DetailsRow>
                              <DetailsRow>
                                  <DetailsLabel> Account Balance: </DetailsLabel>
                                  <DetailsText>
                                      {" "}
                                      {formatCurrency(
                                          props && props.selectedCustomer && props.selectedCustomer.billingBalance
                                      )}
                                  </DetailsText>
                              </DetailsRow>
                          </DetailsColumn>
                          {/*<DetailsColumn*/}
                          {/*    item*/}
                          {/*    sx={{width: {xs: "100%", lg: "33.3%"}}}*/}
                          {/*>*/}
                          {/*    <Typography*/}
                          {/*        sx={{marginBottom: "5px", fontSize: "20px"}}*/}
                          {/*        id="modal-modal-title"*/}
                          {/*        variant="h5"*/}
                          {/*        component="h4"*/}
                          {/*    >*/}
                          {/*        Status Information*/}
                          {/*    </Typography>*/}
                          {/*    <DetailsRow>*/}
                          {/*        <DetailsLabel> Billing Code: </DetailsLabel>*/}
                          {/*        <DetailsText>*/}
                          {/*            {" "}*/}
                          {/*            {props && props.selectedCustomer && props.selectedCustomer.billingCode}*/}
                          {/*        </DetailsText>*/}
                          {/*    </DetailsRow>*/}
                          {/*</DetailsColumn>*/}

                          <DetailsColumn
                              item
                              sx={{width: {xs: "100%", lg: "33.3%"}}}
                          >
                              <Typography
                                  sx={{marginBottom: "5px", fontSize: "20px"}}
                                  id="modal-modal-title"
                                  variant="h5"
                                  component="h4"
                              >
                                  Property Information
                              </Typography>
                              <DetailsRow>
                                  <DetailsLabel> Property: </DetailsLabel>
                                  <DetailsText>
                                      {props && props.selectedCustomer && props.selectedCustomer.facilityName}
                                  </DetailsText>
                              </DetailsRow>
                          </DetailsColumn>
                          <DetailsColumn
                              item
                              sx={{width: {xs: "100%", lg: "33.3%"}}}
                          >
                              <Typography
                                  sx={{marginBottom: "5px", fontSize: "20px"}}
                                  id="modal-modal-title"
                                  variant="h5"
                                  component="h4"
                              >
                                  Balance Information
                              </Typography>
                              <DetailsRow>
                                  <DetailsLabel> Available Credit Limit: </DetailsLabel>
                                  <DetailsText>
                                      {formatCurrency(
                                          props && props.selectedCustomer &&
                                          props.selectedCustomer.availableCreditLimit
                                      )}
                                  </DetailsText>
                              </DetailsRow>
                              <DetailsRow>
                                  <DetailsLabel> Absolute Credit Limit: </DetailsLabel>
                                  <DetailsText>
                                      {formatCurrency(
                                          props && props.selectedCustomer &&
                                          props.selectedCustomer.absoluteCreditLimit
                                      )}
                                  </DetailsText>
                              </DetailsRow>
                          </DetailsColumn>
                      </DetailsContainer>
                  </DetailsCardContainer>
              </ResponsiveRow>
          </Permit>

          <Permit services="BLWTR">
              <ResponsiveRow
                  sx={{
                      width: "100%",
                      height: "auto",
                      flexDirection: { xs: "column", lg: "row" },
                      flexWrap: "nowrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: { xs: 0, lg: "10px auto 5px auto" },
                      padding: 0,
                  }}
              >
                  <Grid
                      item
                      sx={{
                          width: {xs: "100%", lg: "65%"},
                          height: "100%",
                      }}
                  >
                      <ResponsiveRow
                          sx={{
                              flexDirection: "column",
                              backgroundColor: "white",
                              borderRadius: "10px",
                              height: "100%",
                          }}
                      >
                          <Typography
                              sx={{
                                  color: "#037171",
                                  padding: "10px 10px 5px 10px",
                                  textAlign: "start",
                                  width: "100%",
                              }}
                              id="modal-modal-title"
                              variant="h5"
                              component="h4"
                          >
                              {props && props.pendingTransactions && props.pendingTransactions.length}{" "}
                              Pending Transaction(s)
                          </Typography>
                          <TableContainer
                              style={{maxHeight: "450px", minHeight: "300px"}}
                          >
                              <Table aria-label="simple table" stickyHeader>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell sx={{width: "20%"}}>Date</TableCell>
                                          <TableCell sx={{width: "60%"}}>
                                              Transaction Details
                                          </TableCell>
                                          <TableCell sx={{width: "20%"}}>
                                              Amount
                                          </TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {props &&
                                          props.pendingTransactions &&
                                          props.pendingTransactions.length > 0 &&
                                          props.pendingTransactions.map(function (
                                                  transaction,
                                                  i
                                              ) {
                                                  return (
                                                      <TableRow key={transaction.id}>
                                                          <TableCell>
                                                              {formatDate(transaction.entryDate)}
                                                          </TableCell>
                                                          <TableCell>
                                                              {transaction.description}
                                                          </TableCell>
                                                          <TableCell>
                                                              {formatCurrency(
                                                                  transaction.transactionSum
                                                              )}
                                                          </TableCell>
                                                      </TableRow>
                                                  );
                                              },
                                              this)}
                                  </TableBody>
                              </Table>
                          </TableContainer>
                      </ResponsiveRow>
                  </Grid>

                  <DocumentStore
                      item={props && props.selectedCustomer}
                      type={"Customer"}
                      height={"500px"}
                      width={"100%"}
                  />
              </ResponsiveRow>
          </Permit>

          <Permit services="MAINT">
              <TransactionHistory
                  transactionMode={"Client Customer"}
                  contract={props && props.selectedCustomer}
                  handleVoidClick={props.handleVoidClick}
              />
          </Permit>

          <Permit services="BLWTR">
              <TransactionHistory
                  transactionMode={"Customer"}
                  contract={props && props.selectedCustomer}
                  handleVoidClick={props.handleVoidClick}
              />
          </Permit>

      </ResponsiveRow>
    </Permit>
  );
}
