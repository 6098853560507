import LeaseViewBar from "../components/bars/leaseViewBar";
import ChargesCard from "../components/cards/chargesCard";
import LeaseInfoCard from "../components/cards/leaseInformationCard";
import NoteLogsCard from "../components/cards/noteLogsCard";
import TransactionHistory from "../components/transactionHistory";
import { DocumentStore } from "../components/documentStore";
import { ResponsiveRow } from "../constants/layout.constants";
import Permit from "../util/permit";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {isEmptyString, isNullUndefined} from "../util/util";

export default function LeaseView(props) {
    const { user } = useSelector((state) => state.auth)
    const [proxySubscription, setProxySubscription] = useState("")
    const [proxyFacility, setProxyFacility] = useState("")
    useEffect(() => {
        if (!isNullUndefined(user) && (user.proxySubscription !== "" || user.proxyFacility !== "")) {
           setProxySubscription(user.proxySubscription)
            setProxyFacility(user.proxyFacility)
        }
    }, []);

    useEffect(() => {
        if (!isEmptyString(proxyFacility) || !isEmptyString(proxySubscription)) {
            if(user.proxySubscription !== proxySubscription || user.proxyFacility !== proxyFacility){
                props.setTask("")
            }
        }
    }, [user.proxyFacility, user.proxySubscription]);
  return (
    <Permit
      roles="BLDIR,BLDMG,REC,OWN,CS"
      services="PREMIUM,CORE,LEAN"
      noPermittedServiceMessage={{
        title: "Access Denied",
        body: (
          <>
            Your current role and/or facility is not permitted to view this
            page. <br /> Please contact your system admin if you feel this is an
            error.
          </>
        ),
      }}
    >
      <ResponsiveRow
        container
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",
          width: "100%",
          height: "auto",
          justifyContent: { xs: "flex-start", lg: "flex-start" },
          alignItems: "flex-start",
          padding: 0,
        }}
      >
        <LeaseViewBar
          handleStatementYearChange={props.handleStatementYearChange}
          statementYear={props.statementYear}
          handleUnitSwapClick={props.handleUnitSwapClick}
          selectedLease={props.selectedLease}
          setTask={props.setTask}
        />

        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            justifyContent: { xs: "center", lg: "space-between" },
            alignItems: "center",
            padding: 0,
            margin: "10px auto 5px auto",
          }}
        >
          <LeaseInfoCard selectedLease={props.selectedLease} />
        </ResponsiveRow>
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
            flexDirection: { xs: "column", lg: "row" },
            flexWrap: "nowrap",
            justifyContent: "space-between",
            alignItems: "center",
            margin: { xs: 0, lg: "10px auto 5px auto" },
            padding: 0,
          }}
        >
          <NoteLogsCard
            width="30%"
            notesMode={"Lease"}
            contract={props.selectedLease}
            height="500px"
          />

          <ChargesCard
            width="60%"
            chargesMode={"Lease"}
            selectedLease={props.selectedLease}
            charges={props.selectedLease.leaseChargeItems}
          />

          <DocumentStore
            height="500px"
            maxWidth="30%"
            files={[
              ...props.selectedLease.imageFiles,
              ...props.selectedLease.otherDocuments,
            ]}
            item={props.selectedLease}
            type={"Lease"}
          />
        </ResponsiveRow>

        <TransactionHistory
          transactionMode={"Lease"}
          contract={props.selectedLease}
          handleVoidClick={props.handleVoidClick}
          height="55vh"
        />
      </ResponsiveRow>
    </Permit>
  );
}
