import {
  Alert,
  FormControl,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ModalAcceptButton } from "../../../../constants/component.constants";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import ModalContainerModule from "../../../modules/modalContainer";
import dayjs from "dayjs";
import { Add } from "@mui/icons-material";
import kodi_theme from "../../../../themes/kodiThemeProvider";

export default function CreateOwnershipModal(props) {
  /**
   *
   * REQUIRED PROPS
   *
   * task
   * saveOwnership
   * updateOwnership
   *
   * handleAddOwnershipClose
   * handleEndDateChange
   * handleOwnershipChargeItemAdd
   * handleStartDateChange
   * handleTenantSwitchToggle
   * handleUnitChange
   *
   * setIsTenantSelect
   * setIsHomeOwnerSelect
   * setTenantModal
   *
   * chargeItemsTotal
   * endDate
   * homeOwnersTableRows
   * facilityOptions
   * ownershipChargesTableRows
   * selectedOwnership
   * startDate
   * selectedUnit
   * tenantOccupied
   * unitOptions
   *
   */

  return (
    <ModalContainerModule
      size="md"
      accept={
        props.task === "Create"
          ? props.saveOwnership
          : props.task === "Edit"
          ? props.updateOwnership
          : null
      }
      cancel={props.handleAddOwnershipClose}
      openModal={props.task === "Create" || props.task === "Edit"}
      modalTitle={` Ownership ${
        props.selectedOwnership && props.selectedOwnership.billingCode
      }`}
      acceptButtonText={
        props.task === "Create"
          ? "Create"
          : props.task === "Edit"
          ? "Update"
          : ""
      }
    >
      <ResponsiveRow
        sx={{
          height: "auto",
        }}
      >
        <Typography
          sx={{ marginBottom: "5px" }}
          id="modal-modal-title"
          variant="h5"
          component="h4"
        >
          Ownership Details
        </Typography>
        <ResponsiveRow container>
          {props.task === "Create" && (
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label="Start date"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"startDate"}
                  value={dayjs(props.startDate)}
                  isrequired
                  disabled={false}
                  onChange={(newDate) => props.handleStartDateChange(newDate)}
                />
              </LocalizationProvider>
            </FormControl>
          )}

          {props.task === "Edit" && (
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DatePicker
                  label="End date"
                  sx={{ width: { xs: "100%", lg: "90%" } }}
                  id={"endDate"}
                  value={dayjs(props.endDate)}
                  isrequired
                  disabled={false}
                  onChange={(newDate) => props.handleEndDateChange(newDate)}

                />
              </LocalizationProvider>
            </FormControl>
          )}
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Facility</InputLabel>
            <Select
              label="Facility"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"facility"}
              value={
                props.selectedOwnership && props.selectedOwnership.facilityId
              }
              disabled
              onChange={() => console.log()}
            >
              {props.facilityOptions && props.facilityOptions.length > 0 ? (
                props.facilityOptions.map(function (facility, i) {
                  return (
                    <MenuItem key={i} value={facility.value}>
                      {facility.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Unit</InputLabel>
            <Select
              label="Unit"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"unit"}
              value={props.selectedUnit}
              disabled={props.task === "Edit"}
              onChange={(event) => props.handleUnitChange(event)}
            >
              {props.unitOptions && props.unitOptions.length > 0 ? (
                props.unitOptions.map(function (unit, i) {
                  return (
                    <MenuItem key={i} value={unit.value}>
                      {unit.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
          <ResponsiveRow
            item
            sx={{
              justifyContent: { xs: "center", lg: "flex-start" },
              alignItems: "center",
              marginBottom: "10px",
              height: "100%",
              margin: "auto",
            }}
          >
            <FormControl
              item
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                height: "55px",
                marginBottom: "10px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <FormLabel>Does the home have a tenant?</FormLabel>
              <Switch
                checked={props.tenantOccupied}
                onChange={(event) => props.handleTenantSwitchToggle(event)}
                inputProps={{ "aria-label": "controlled" }}
              />
            </FormControl>
            {props.tenantOccupied && (
              <ResponsiveRow
                component={FormControl}
                item
                sx={{
                  width: { xs: "100%", lg: "50%" },
                  marginBottom: "10px",
                  padding: 0,
                }}
              >
                <InputLabel>Tenant</InputLabel>
                <OutlinedInput
                  label="Tenant"
                  sx={{ width: "55%" }}
                  id={"tenant"}
                  value={
                    props.selectedOwnership &&
                    props.selectedOwnership.tenant &&
                    props.selectedOwnership.tenant.name
                  }
                  disabled={props.tenantOccupied}

                />
                <IconButton
                  onClick={() => {
                    props.setTenantModal(true);
                    props.setIsTenantSelect(true);
                  }}
                  sx={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: kodi_theme.palette.primary.dark,
                    margin: "auto 10px",
                    padding: "15px",
                  }}
                >
                  <Add sx={{ color: kodi_theme.palette.common.white }} />
                </IconButton>
              </ResponsiveRow>
            )}
          </ResponsiveRow>

          {props.task === "Create" && (
            <FormControl
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Incoming Balance</InputLabel>
              <OutlinedInput
                label={"Incoming Balance"}
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"balance"}
                name={"balance"}
                onChange={(event) => props.handleBalanceChange(event)}
                value={props.balance}

              />
            </FormControl>
          )}

          {props.task === "Create" && (
            <FormControl
              sx={{
                width: { xs: "100%", lg: "33.3%" },
                marginBottom: "10px",
              }}
            >
              <InputLabel>Incoming Balance Notes</InputLabel>
              <OutlinedInput
                label={"Incoming Balance Notes"}
                sx={{ width: { xs: "100%", lg: "90%" } }}
                id={"balanceNotes"}
                name={"balanceNotes"}
                onChange={(event) => props.handleBalanceNotesChange(event)}
                value={props.balanceNotes}

              />
            </FormControl>
          )}
        </ResponsiveRow>
      </ResponsiveRow>
      <ResponsiveRow sx={{ height: "auto" }}>
        <Typography
          sx={{ marginBottom: "5px" }}
          id="modal-modal-title"
          variant="h5"
          component="h4"
        >
          Home Owners
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "30%" }}>Name</TableCell>
                <TableCell sx={{ width: "20%" }}>Type</TableCell>
                <TableCell sx={{ width: "30%" }}>Phone Number</TableCell>
                <TableCell sx={{ width: "10%" }}>Invoiced?</TableCell>
                <TableCell sx={{ width: "10%" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{props.homeOwnersTableRows}</TableBody>
          </Table>
        </TableContainer>

        <ResponsiveRow
          sx={{ justifyContent: { xs: "center", lg: "flex-end" } }}
        >
          <ModalAcceptButton
            onClick={() => {
              props.setTenantModal(true);
              props.setIsHomeOwnerSelect(true);
            }}
            sx={{ width: { xs: "100%", lg: "200px" } }}
          >
            Add Home Owner
          </ModalAcceptButton>
        </ResponsiveRow>
      </ResponsiveRow>
      <ResponsiveRow sx={{ height: "auto" }}>
        <Typography
          sx={{ marginBottom: "5px" }}
          id="modal-modal-title"
          variant="h5"
          component="h4"
        >
          Ownership Charges
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "15%" }}>Unit</TableCell>
                <TableCell sx={{ width: "15%" }}>Charge Type</TableCell>
                <TableCell sx={{ width: "20%" }}>Amount</TableCell>
                <TableCell sx={{ width: "20%" }}>Billing Period</TableCell>
                <TableCell sx={{ width: "20%" }}>Invoice Day/Date</TableCell>
                <TableCell sx={{ width: "10%" }}>Taxed?</TableCell>
                <TableCell sx={{ width: "10%" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{props.ownershipChargesTableRows}</TableBody>
          </Table>
        </TableContainer>
        <ResponsiveRow xs={{ padding: 0, width: "100%" }}>
          <Alert sx={{ width: "100%" }} severity="info">
            Total: {props.chargeItemsTotal}
          </Alert>
        </ResponsiveRow>
        <ResponsiveRow
          sx={{ justifyContent: { xs: "center", lg: "flex-end" } }}
        >
          <ModalAcceptButton
            onClick={() => props.handleOwnershipChargeItemAdd()}
            sx={{ width: { xs: "100%", lg: "200px" } }}
          >
            Add Row
          </ModalAcceptButton>
        </ResponsiveRow>
      </ResponsiveRow>
    </ModalContainerModule>
  );
}
