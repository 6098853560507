import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ModalContainerModule from "../../../modules/modalContainer";
import { ResponsiveRow } from "../../../../constants/layout.constants";
import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { orgBankAccounts } from "../../../../services/bank.service";
import { isNullUndefined } from "../../../../util/util";
import { issueErrorMessage } from "../../../../actions/message";
import { savePettyCashEntry } from "../../../../services/pettyCash.service";
import { ledgerAccForBank } from "../../../../services/list.service";

const PettyCashTopup = (props) => {
  const [transaction, setTransaction] = useState();
  const [orgBankAccountsList, setOrgBankAccountsList] = useState([]);
  const [ledgerAccountsList, setLedgerAccountsList] = useState([]);

  const user = useSelector((state) => state.auth.user);
  const saveTopup = () => {
    if (isNullUndefined(transaction.postingDate)) {
      issueErrorMessage("Date is required");
      return;
    }
    if (isNullUndefined(transaction.amount)) {
      issueErrorMessage("Amount is required");
      return;
    }
    if (isNullUndefined(transaction.bankAccountId)) {
      issueErrorMessage("A Source Bank Account is required");
      return;
    }
    if (isNullUndefined(transaction.ledgerAccountId)) {
      issueErrorMessage("A Ledger Account is required");
      return;
    }
    if (isNullUndefined(transaction.description)) {
      issueErrorMessage("Memo is required");
      return;
    }
    savePettyCashEntry(transaction)
      // .then((data) => {
      //   console.log("data", data);
      // })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        props.close(false);
      });
  };

  useEffect(() => {
    setTransaction({
      postingDate: null,
      description: null,
      facilityId: user.proxyFacility,
      bankAccountId: null,
      ledgerAccountId: null,
      amount: null,
    });
    orgBankAccounts()
      .then((data) => setOrgBankAccountsList(data))
      .catch((error) => {
        console.log("error", error);
      });
  }, [user.proxyFacility]);

  useEffect(() => {
    if (transaction?.bankAccountId) {
      ledgerAccForBank(transaction.bankAccountId)
        .then((data) => setLedgerAccountsList(data))
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [transaction?.bankAccountId]);

  return (
    <ModalContainerModule
      size="sm"
      accept={saveTopup}
      cancel={() => {
        props.close(false);
      }}
      openModal={props.open}
      modalTitle="Top-up Petty Cash"
      acceptButtonText="Save"
    >
      <ResponsiveRow container sx={{ height: "auto" }}>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "50%" },
            marginBottom: "10px",
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              label="Date"
              sx={{ width: { xs: "100%", lg: "90%" } }}
              id={"postingDate"}
              isrequired={true}
              disableFuture
              value={
                transaction ? dayjs(transaction.postingDate) : dayjs(props.date)
              }
              onChange={(newDate) => {
                setTransaction({ ...transaction, postingDate: newDate });
              }}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "50%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>Amount</InputLabel>
          <OutlinedInput
            sx={{ width: "90%" }}
            id={"amount"}
            type="number"
            label="Amount"
            isrequired={true}
            value={transaction?.amount ?? ""}
            onChange={(event) =>
              setTransaction({
                ...transaction,
                amount: event.target.value,
              })
            }
          />
        </FormControl>
      </ResponsiveRow>
      <ResponsiveRow container sx={{ height: "auto" }}>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "50%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>Source Bank Account</InputLabel>
          <Select
            sx={{ width: { xs: "100%", md: "90%" } }}
            id={"bankAccountId"}
            label="Source Bank Account"
            value={transaction?.bankAccountId ?? ""}
            isrequired={true}
            onChange={(event) =>
              setTransaction({
                ...transaction,
                bankAccountId: event.target.value,
              })
            }
          >
            {orgBankAccountsList.length > 0 ? (
              orgBankAccountsList.map(function (account, i) {
                return (
                  <MenuItem key={account.value} value={account.value}>
                    {account.label}
                  </MenuItem>
                );
              }, this)
            ) : (
              <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
            )}
          </Select>
        </FormControl>
        {transaction?.bankAccountId && (
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "50%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Ledger Account</InputLabel>
            <Select
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"ledgerAccountId"}
              value={transaction?.ledgerAccountId ?? ""}
              isrequired={true}
              onChange={(event) =>
                setTransaction({
                  ...transaction,
                  ledgerAccountId: event.target.value,
                })
              }
            >
              {ledgerAccountsList.length > 0 ? (
                ledgerAccountsList.map(function (account, i) {
                  return (
                    <MenuItem key={account.value} value={account.value}>
                      {account.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>
        )}
      </ResponsiveRow>
      <ResponsiveRow container sx={{ height: "auto" }}>
        <FormControl
          item
          sx={{
            width: { xs: "100%", lg: "100%" },
            marginBottom: "10px",
          }}
        >
          <InputLabel>Memo</InputLabel>
          <OutlinedInput
            label="Memo"
            id="description"
            type="text"
            sx={{
              width: { xs: "100%", md: "90%" },
            }}
            onChange={(event) => {
              setTransaction({
                ...transaction,
                description: event.target.value,
              });
            }}
            value={transaction?.description ?? ""}
            isrequired={true}
            multiline
            rows={4}
          />
        </FormControl>
      </ResponsiveRow>
    </ModalContainerModule>
  );
};

export default PettyCashTopup;
