/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Alert,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useCallback, useEffect, useState } from "react";
import {
  getCountries,
  getIdTypes,
  getRentDays,
} from "../../services/list.service";
import {
  isEmptyString,
  isNullUndefined,
  isValidDate,
  tryParseFloat,
  tryParseInt,
} from "../../util/util";
import {fetchOrganization, saveOrganization} from "../../services/organisation.service";
import Permit from "../../util/permit";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { getMeterBillingPeriods } from "../../services/meterbilling.service";
import { ResponsiveRow } from "../../constants/layout.constants";
import ModalContainerModule from "../modules/modalContainer";
import {
  issueErrorMessage,
  issueResponseMessage,
  issueWarnMessage,
} from "../../actions/message";
import dayjs from "dayjs";

/**
 *Required props are:
 - openModal: True or false to show or hide modal respectively.
 - closeModal: Function to close the modal ie set showTerminateModal to false.
 - selectedOrg: Organisation data to populate form.
 - searchedName: To preload organisation name when adding a new user
 - customer: Boolean. To render additional credit limit component.
 - task: Edit
 - saveOrg: To save an organisation customer
 */

export default function AddNewOrganisation(props) {
  const [companyName, setCompanyName] = useState("");
  const [description, setDescription] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [countryCd, setCountry] = useState("");
  const [taxId, setTaxId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [officePhone, setOfficePhone] = useState("");
  const [email, setEmail] = useState("");
  const [idNoSearch, setIdNo] = useState("");
  const [idTypeSearch, setIdType] = useState("");
  const [countryOptions, setCountryOptions] = useState([]);
  const [idTypes, setIdTypes] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [approveCapitalFunds, setApproveCapitalFunds] = useState(false);
  const [viewOnlyAccess, setViewOnlyAccess] = useState(false);
  const [approveBudgets, setApproveBudgets] = useState(false);
  const [billingPeriods, setBillingPeriods] = useState([]);
  const [rentDays, setRentDays] = useState([]);
  const [billingDate, setBillingDate] = useState("");

  const fetchUserDetails = useCallback(() => {
    let data = {
      id: null,
      name: props.searchedName,
      description: "",
      taxId: "",
      address: {
        id: "",
        address1: "",
        address2: "",
        countryCd: "",
        city: "",
      },
      contact: {
        id: "",
        firstName: "",
        middleName: "",
        lastName: "",
        mobilePhone: "",
        officePhone: "",
        email: "",
        credentialId: "",
        idNumber: "",
        idTypeCd: "",
      },
      absoluteCreditLimit: "",
      billingPeriod: "",
      billingDay: "",
      type: "Company",
    };
    getCountries().then((countryOptions) => setCountryOptions(countryOptions));
    getIdTypes().then((idTypes) => setIdTypes(idTypes));
    getMeterBillingPeriods().then((billingPeriods) =>
      setBillingPeriods(billingPeriods)
    );
    getRentDays().then((rentDays) => setRentDays(rentDays));

    if (isNullUndefined(props.selectedOrg)) {
      setSelectedOrg(data);
      setCompanyName(props.searchedName);
    }
  }, [props.searchedName]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  useEffect(() => {
    if (!isNullUndefined(props.task) && props.task === "Edit") {
      setSelectedOrg(props.selectedOrg);
      if (selectedOrg && selectedOrg.permissions) {
        if (selectedOrg.permissions.indexOf("view only access") >= 0) {
          setViewOnlyAccess(true);
        }
        if (selectedOrg.permissions.indexOf("capital fund") >= 0) {
          setApproveCapitalFunds(true);
        }
        if (selectedOrg.permissions.indexOf("budget") >= 0) {
          setApproveBudgets(true);
        }
      }
    }
  }, [selectedOrg]);

  useEffect(() => {
    // console.log("props.selectedOrg data", props.selectedOrg)
    if (!isNullUndefined(props.selectedOrg && props.selectedOrg.id)) {
      const orgIdString = props.selectedOrg && props.selectedOrg.id
      const splitString = orgIdString.split("_")
      fetchOrganization(splitString && splitString[0]).then(organization => {
        // console.log("fetched Org data", organization)
        setSelectedOrg(organization);
      })
    }
  }, []);

  function handleSwitchToggleChanged(event, permission) {
    let obj = selectedOrg;
    if (permission === "capital fund") {
      if (event.target.checked) {
        obj.permissions.push(permission);
        setApproveCapitalFunds(true);
      } else {
        obj.permissions.splice(obj.permissions.indexOf("capital fund"));
        setApproveCapitalFunds(false);
      }
    } else if (permission === "budget") {
      if (event.target.checked) {
        obj.permissions.push(permission);
        setApproveBudgets(true);
      } else {
        obj.permissions.splice(obj.permissions.indexOf("budget"));
        setApproveBudgets(false);
      }
    } else if (permission === "view only access") {
      if (event.target.checked) {
        obj.permissions.push(permission);
        setViewOnlyAccess(true);
      } else {
        obj.permissions.splice(obj.permissions.indexOf("view only access"));
        setViewOnlyAccess(false);
      }
    }
    setSelectedOrg(obj)
  }

  function handleNextBillingDateChange(value) {
    let date = new Date(value)
    let obj = selectedOrg
    obj.billingDate = date
    setSelectedOrg(obj)
    setBillingDate(date)
  }

  function handleBillingPeriodChange(event) {
    let value = event.target.value
    let obj = selectedOrg
    obj.billingPeriod = value
    setSelectedOrg(obj)
  }

  function handleBillingDayChange(event) {
    let value = event.target.value
    let obj = selectedOrg
    obj.billingDay = value
    setSelectedOrg(obj)
  }

  function handleIdTypeChange(event) {
    let value = event.target.value
    setIdType(value)
    let tmp = selectedOrg
    tmp.contact.idTypeCd = value
    setSelectedOrg(tmp)
  }

  function handleCountryChange(event) {
    let value = event.target.value
    setCountry(value)
    let tmp = selectedOrg
    tmp.address.countryCd = value
    setSelectedOrg(tmp)
  }

  function validateForm() {
    let sendData = selectedOrg;
    if (isNullUndefined(sendData)) {
      issueErrorMessage(
        "Could not find user data, please refresh the page and try again."
      );
      return false;
    }
    if (isEmptyString(sendData.name)) {
      issueWarnMessage("Company name is required.");
      return false;
    }
    if (isEmptyString(sendData.address.address1)) {
      issueWarnMessage("Address is required.");
      return false;
    }
    if (isEmptyString(sendData.address.city)) {
      issueWarnMessage("City is required.");
      return false;
    }
    if (isEmptyString(sendData.address.countryCd)) {
      issueWarnMessage("Please select a country");
      return false;
    }
    if (
      isEmptyString(sendData.contact.firstName) ||
      isEmptyString(sendData.contact.lastName)
    ) {
      issueWarnMessage("First name and Last name are required.");
      return false;
    }
    if (
      isNullUndefined(sendData.contact.mobilePhone) ||
      isEmptyString(sendData.contact.mobilePhone)
    ) {
      issueWarnMessage("Mobile Phone number is required.");
      return false;
    }
    if (
      isNullUndefined(sendData.contact.idNumber) ||
      isEmptyString(sendData.contact.idNumber) ||
      isNullUndefined(sendData.contact.idTypeCd) ||
      isEmptyString(sendData.contact.idTypeCd)
    ) {
      issueWarnMessage("Must provide and ID number and ID type.");
      return false;
    }
    if (
      isNullUndefined(sendData.contact.email) ||
      isEmptyString(sendData.contact.email)
    ) {
      issueWarnMessage("Must provide an email.");
      return false;
    }
    if (props.customer) {
      let creditLimit = tryParseFloat(sendData.absoluteCreditLimit, -1);
      if (
        isNullUndefined(sendData.billingPeriod) ||
        isEmptyString(sendData.billingPeriod)
      ) {
        issueWarnMessage("Must provide a billing Frequency.");
        return false;
      }

      if (creditLimit < 0) {
        issueWarnMessage("Must provide a valid credit Limit.");
        return false;
      }
      sendData.absoluteCreditLimit = creditLimit;

      if (sendData.billingPeriod === "MNTH") {
        if (
          isNullUndefined(sendData.billingDay) ||
          isEmptyString(sendData.billingDay)
        ) {
          issueWarnMessage("Must provide a billing day.");
          return false;
        }
        let billingDay = tryParseInt(sendData.billingDay, -1);
        if (billingDay < 0) {
          issueWarnMessage("Must provide a valid billing day.");
          return false;
        }
        sendData.billingDay = billingDay;
      }

      if (sendData.billingPeriod === "QT" || sendData.billingPeriod === "ANU") {
        if (
          isNullUndefined(sendData.billingDate) ||
          isEmptyString(sendData.billingDate)
        ) {
          issueWarnMessage("Must provide a billing date.");
          return false;
        }
        let date = new Date(sendData.billingDate);
        if (!isValidDate(date)) {
          issueWarnMessage("Must provide a valid date.");
          return false;
        }
        sendData.billingDate = date;
      }
    }
    setSelectedOrg(sendData);
    return true;
  }

  function handleSaveOrgClicked() {
    if (validateForm()) {
      if (!isNullUndefined(props.handleSave) && props.handleSave) {
        props.saveOrg(selectedOrg);
        closeModal();
      } else {
        saveOrganization(selectedOrg).then((response) => {
          issueResponseMessage(response);
          closeModal();
        });
      }
    }
  }

  function closeModal() {
    props.closeModal();
    setSelectedOrg(null);
  }

  return (
    <>
      <ModalContainerModule
        size="md"
        accept={handleSaveOrgClicked}
        openModal={props.openModal}
        cancel={props.closeModal}
        modalTitle={`${
          isNullUndefined(props.task) ? "Add" : props.task
        } Organization`}
        acceptButtonText={
          !isNullUndefined(props.task) && props.task === "Edit"
            ? "Update"
            : "Create Organisation"
        }
      >
        <Typography
          sx={{ marginBottom: "20px" }}
          id="addUser"
          variant="h5"
          component="h4"
        >
          Organization Details
        </Typography>

        <ResponsiveRow sx={{ height: "auto" }} container>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Company Name *</InputLabel>
            <OutlinedInput
              label={"Company Name"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"companyName"}
              onChange={(event) => {
                const value = event.target.value
                setCompanyName(value)
                let tmp = selectedOrg
                tmp.name = value
                setSelectedOrg(tmp)
              }}
              value={
                selectedOrg ? selectedOrg && selectedOrg.name : companyName
              }

            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Address 1 *</InputLabel>
            <OutlinedInput
              label={"Address 1"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"address1"}
              onChange={(event) => {
                const value = event.target.value
                setAddress1(value)
                let tmp = selectedOrg
                tmp.address.address1 = value;
                setSelectedOrg(tmp)
              }}
              value={
                selectedOrg
                  ? selectedOrg &&
                    selectedOrg.address &&
                    selectedOrg.address.address1
                  : address1
              }

            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Address 2</InputLabel>
            <OutlinedInput
              label={"Address 2"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"address2"}
              onChange={(event) => {
                const value = event.target.value
                setAddress2(value)
                let tmp = selectedOrg
                tmp.address.address2 = value
                setSelectedOrg(tmp)
              }}
              value={
                selectedOrg
                  ? selectedOrg &&
                    selectedOrg.address &&
                    selectedOrg.address.address2
                  : address2
              }

            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Country *</InputLabel>
            <Select
              label="Country *"
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"country"}
              value={
                selectedOrg
                  ? selectedOrg &&
                    selectedOrg.address &&
                    selectedOrg.address.countryCd
                  : countryCd
              }
              onChange={(event) => handleCountryChange(event)}
            >
              {countryOptions && countryOptions.length > 0 ? (
                countryOptions.map(function (country, i) {
                  return (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>City *</InputLabel>
            <OutlinedInput
              label={"City"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"city"}
              onChange={(event) => {
                const value = event.target.value
                setCity(value);
                let tmp = selectedOrg
                tmp.address.city = value
                setSelectedOrg(tmp)
              }}
              value={
                selectedOrg
                  ? selectedOrg &&
                    selectedOrg.address &&
                    selectedOrg.address.city
                  : city
              }
            />
          </FormControl>

          <FormControl
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Description</InputLabel>
            <OutlinedInput
              label={"Description"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"description"}
              onChange={(event) => {
                const value = event.target.value
                setDescription(value)
                let tmp = selectedOrg
                tmp.description = value
                setSelectedOrg(tmp)
              }}
              value={
                selectedOrg
                  ? selectedOrg && selectedOrg.description
                  : description
              }

            />
          </FormControl>

          <FormControl
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Tax ID</InputLabel>
            <OutlinedInput
              label={"Tax Id"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"taxId"}
              onChange={(event) => {
                const value = event.target.value
                setTaxId(value)
                let tmp = selectedOrg
                tmp.taxId = value
                setSelectedOrg(tmp)
              }}
              value={selectedOrg ? selectedOrg && selectedOrg.taxId : taxId}
            />
          </FormControl>
        </ResponsiveRow>

        <br />
        <Divider />
        <br />
        <Typography
          sx={{ marginBottom: "20px" }}
          id="addUser"
          variant="h5"
          component="h4"
        >
          Organisation Contact Person
        </Typography>

        <ResponsiveRow sx={{ height: "auto" }} container>
          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>First Name *</InputLabel>
            <OutlinedInput
              label={"First Name"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"firstName"}
              onChange={(event) => {
                const value = event.target.value
                setFirstName(value)
                let tmp = selectedOrg
                tmp.contact.firstName  = value
                setSelectedOrg(tmp)
              }}
              value={
                selectedOrg
                  ? selectedOrg &&
                    selectedOrg.contact &&
                    selectedOrg.contact.firstName
                  : firstName
              }

            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Middle Name</InputLabel>
            <OutlinedInput
              label={"Middle Name"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"middleName"}
              onChange={(event) => {
                const value = event.target.value
                setMiddleName(value)
                let tmp = selectedOrg
                tmp.contact.middleName  = value
                setSelectedOrg(tmp)
              }}
              value={
                selectedOrg
                  ? selectedOrg &&
                    selectedOrg.contact &&
                    selectedOrg.contact.middleName
                  : middleName
              }

            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Last Name *</InputLabel>
            <OutlinedInput
              label={"Last Name"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"lastName"}
              onChange={(event) => {
                const value = event.target.value
                setLastName(value)
                let tmp = selectedOrg
                tmp.contact.lastName  = value
                setSelectedOrg(tmp)
              }}
              value={
                selectedOrg
                  ? selectedOrg &&
                    selectedOrg.contact &&
                    selectedOrg.contact.lastName
                  : lastName
              }
            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Email *</InputLabel>
            <OutlinedInput
              label={"Email"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"email"}
              onChange={(event) => {
                const value = event.target.value
                setEmail(value)
                let tmp = selectedOrg
                tmp.contact.email  = value
                setSelectedOrg(tmp)
              }}
              value={
                selectedOrg
                  ? selectedOrg &&
                    selectedOrg.contact &&
                    selectedOrg.contact.email
                  : email
              }
            />
          </FormControl>

          <FormControl
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>ID Type</InputLabel>
            <Select
              label="ID TYpe"
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"idType"}
              value={
                selectedOrg
                  ? selectedOrg &&
                    selectedOrg.contact &&
                    selectedOrg.contact.idTypeCd
                  : idTypeSearch
              }
              onChange={(event) => handleIdTypeChange(event)}
            >
              {idTypes && idTypes.length > 0 ? (
                idTypes.map(function (idType, i) {
                  return (
                    <MenuItem key={idType.value} value={idType.value}>
                      {idType.label}
                    </MenuItem>
                  );
                }, this)
              ) : (
                <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
              )}
            </Select>
          </FormControl>

          <FormControl
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>ID Number *</InputLabel>
            <OutlinedInput
              label={"ID Number *"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"idNo"}
              onChange={(event) => {
                const value = event.target.value
                setIdNo(value)
                let tmp = selectedOrg
                tmp.contact.idNumber  = value
                setSelectedOrg(tmp)
              }}
              value={
                selectedOrg
                  ? selectedOrg &&
                    selectedOrg.contact &&
                    selectedOrg.contact.idNumber
                  : idNoSearch
              }
            />
          </FormControl>

          <FormControl
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Mobile Phone *(Primary No)</InputLabel>
            <OutlinedInput
              label={"Mobile Phone * (Primary No)"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"mobilePhone"}
              onChange={(event) => {
                const value = event.target.value
                setMobilePhone(value)
                let tmp = selectedOrg
                tmp.contact.mobilePhone  = value
                setSelectedOrg(tmp)
              }}
              value={
                selectedOrg
                  ? selectedOrg &&
                    selectedOrg.contact &&
                    selectedOrg.contact.mobilePhone
                  : mobilePhone
              }

            />
          </FormControl>

          <FormControl
            item
            sx={{
              width: { xs: "100%", lg: "33.3%" },
              marginBottom: "10px",
            }}
          >
            <InputLabel>Office Phone(Secondary No)</InputLabel>
            <OutlinedInput
              label={"Office Phone(Secondary No)"}
              sx={{ width: { xs: "100%", md: "90%" } }}
              id={"officePhone"}
              onChange={(event) => {
                const value = event.target.value
                setOfficePhone(value)
                let tmp = selectedOrg
                tmp.contact.officePhone  = value
                setSelectedOrg(tmp)
              }}
              value={
                selectedOrg
                  ? selectedOrg &&
                    selectedOrg.contact &&
                    selectedOrg.contact.officePhone
                  : officePhone
              }

            />
          </FormControl>
        </ResponsiveRow>

        <br />
        <ResponsiveRow
          sx={{
            width: "100%",
            height: "auto",
          }}
        >
          {selectedOrg &&
            !isNullUndefined(selectedOrg.roleCode) &&
            selectedOrg.roleCode === "OWN" && (
              <Paper
                variant=""
                sx={{
                  margin: "auto",
                  padding: "10px",
                  textAlign: "left",
                }}
              >
                <Permit roles="BLDIR,BLDMG,REC,CS" services="HOA">
                  <Typography id="permissions" variant="h6" component="h6">
                    Activate Permissions at this Property.
                  </Typography>
                </Permit>
                <Alert severity="info">
                  Activating permission will make an owner a committee member
                  for respective granted permission. That is
                  <span
                    style={{
                      padding: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Capital Fund Committee
                  </span>
                  or
                  <span
                    style={{
                      padding: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Budget Committee
                  </span>
                  or
                  <span
                    style={{
                      padding: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Both.
                  </span>
                </Alert>
                <br />

                <ResponsiveRow sx={{ height: "auto" }} container>
                  <FormControl
                    item
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>View Only Access</InputLabel>
                    <Switch
                      checked={viewOnlyAccess}
                      onChange={(event) =>
                        handleSwitchToggleChanged(event, "view only access")
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </FormControl>

                  <Grid
                    item
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>Is a Capital Fund Committee Member</InputLabel>
                    <Switch
                      checked={approveCapitalFunds}
                      onChange={(event) =>
                        handleSwitchToggleChanged(event, "capital fund")
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Grid>

                  <FormControl
                    item
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      marginBottom: "10px",
                    }}
                  >
                    <InputLabel>Is a Budget Committee Member</InputLabel>
                    <Switch
                      checked={approveBudgets}
                      onChange={(event) =>
                        handleSwitchToggleChanged(event, "budget")
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </FormControl>
                </ResponsiveRow>
              </Paper>
            )}

          {props.customer && (
            <Typography
              sx={{ marginBottom: "20px" }}
              id="addUser"
              variant="h5"
              component="h4"
            >
              Add Credit Limit
            </Typography>
          )}
          {props.customer && (
            <ResponsiveRow sx={{ width: "100%", height: "auto" }} container>
              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Credit Limit *</InputLabel>
                <OutlinedInput
                  id="absoluteCreditLimit"
                  label="Credit Limit *"
                  sx={{
                    width: { xs: "100%", md: "90%" },
                  }}
                  onChange={(event) => {
                    let value = event.target.value
                    let obj = selectedOrg
                    obj.absoluteCreditLimit = value
                    setSelectedOrg(obj)
                  }}
                  value={selectedOrg && selectedOrg.absoluteCreditLimit}
                />
              </FormControl>

              <FormControl
                item
                sx={{
                  width: { xs: "100%", lg: "33.3%" },
                  marginBottom: "10px",
                }}
              >
                <InputLabel>Billing Frequency</InputLabel>
                <Select
                  sx={{ width: { xs: "100%", md: "90%" } }}
                  label="Billing Frequency"
                  id={"billingPeriod"}
                  value={selectedOrg && selectedOrg.billingPeriod}
                  onChange={(event) => handleBillingPeriodChange(event)}
                >
                  {billingPeriods && billingPeriods.length > 0 ? (
                    billingPeriods.map(function (billingPeriod, i) {
                      return (
                        <MenuItem
                          key={billingPeriod.value}
                          value={billingPeriod.value}
                        >
                          {billingPeriod.label}
                        </MenuItem>
                      );
                    }, this)
                  ) : (
                    <MenuItem sx={{ width: "100%" }}>No Results Found</MenuItem>
                  )}
                </Select>
              </FormControl>

              {selectedOrg && selectedOrg.billingPeriod === "MNTH" && (
                <FormControl
                  item
                  sx={{
                    width: { xs: "100%", lg: "33.3%" },
                    marginBottom: "10px",
                  }}
                >
                  <InputLabel>Billing Day</InputLabel>
                  <Select
                    sx={{ width: { xs: "100%", md: "90%" } }}
                    id={"billingDay"}
                    label="Billing Day"
                    value={selectedOrg && selectedOrg.billingDay}
                    onChange={(event) => handleBillingDayChange(event)}
                    defaultValue={[""]}
                  >
                    {rentDays && rentDays.length > 0 ? (
                      rentDays.map(function (weekDay, i) {
                        return (
                          <MenuItem key={weekDay.value} value={weekDay.value}>
                            {weekDay.label}
                          </MenuItem>
                        );
                      }, this)
                    ) : (
                      <MenuItem sx={{ width: "100%" }}>
                        No Results Found
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}

              {selectedOrg &&
                (selectedOrg.billingPeriod === "ANU" ||
                  selectedOrg.billingPeriod === "QT") && (
                  <FormControl
                    item
                    sx={{
                      width: { xs: "100%", lg: "33.3%" },
                      marginBottom: "10px",
                    }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en-gb"
                    >
                      <DatePicker
                        sx={{ width: { xs: "100%", md: "90%" } }}
                        label="Next Billing Date"
                        id={"nextBillingDate"}
                        value={
                          selectedOrg
                            ? selectedOrg && dayjs(selectedOrg.billingDate)
                            : dayjs(billingDate)
                        }
                        onChange={(newDate) =>
                          handleNextBillingDateChange(newDate)
                        }

                      />
                    </LocalizationProvider>
                  </FormControl>
                )}
            </ResponsiveRow>
          )}
        </ResponsiveRow>
      </ModalContainerModule>
    </>
  );
}
